import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";
import { ReactComponent as FlagUK } from "images/flag-gb-svgrepo-com.svg";
import { ReactComponent as FlagFinland } from "images/flag-fi-svgrepo-com.svg";
import { useTranslation } from "react-i18next";

const Row = tw.div`px-4 sm:px-16 mx-auto flex justify-center items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`;
const TextContainer = tw(ColumnContainer)`text-2xl sm:text-4xl font-bold`;
const Subheading = tw.h6`text-primary-500 opacity-75 text-lg`; // xs for extra small
const Heading = tw.h5`text-primary-500 text-lg`; // sm for small

const LinksContainer = tw(ColumnContainer)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const Link = tw.a`flex items-center justify-center w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;

const SecondaryLink = tw(Link)`text-primary-500 hover:text-primary-600 bg-gray-100 hover:bg-gray-200 rounded-full`;

export default function GetStartedLight() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const primaryLinkText = "ENGLISH";
  const secondaryLinkText = "SUOMI";
  const pushDownFooter = true;

  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <Row>
          <TextContainer>
            {t("available_languages") && <Subheading>{t("available_languages")}</Subheading>}
            <Heading>{t("your_language_missing")}</Heading>
          </TextContainer>
          <LinksContainer>
            <SecondaryLink onClick={() => changeLanguage("en")}>
              <FlagUK css={tw`w-10 h-10 mr-5`} />
              {primaryLinkText}
            </SecondaryLink>
            <SecondaryLink onClick={() => changeLanguage("fi")}>
              <FlagFinland css={tw`w-10 h-10 mr-5`} />
              {secondaryLinkText}
            </SecondaryLink>
          </LinksContainer>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
}
