import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-8 leading-relaxed`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: April 21, 2048</p>
            <p>
              Ludus Super Oy built the SUPERSMART GO! app as a Freemium app. This SERVICE is provided by Ludus Super Oy at no cost and is intended for
              use as is.
            </p>
            <p>
              This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone
              decided to use our Service.
            </p>
            <p>
              If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal
              Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone
              except as described in this Privacy Policy.
            </p>
            <p>
              The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at SUPERSMART GO!
              unless otherwise defined in this Privacy Policy.
            </p>
            <h1>Information Collection and Use</h1>
            <p>
              For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The
              information that we request will be retained by us and used as described in this privacy policy.
            </p>
            <p>
              Our application uses Google OAuth to facilitate the creation, editing, and deletion of your Google Play Games activities. This includes
              publishing in-app achievements to your Google Play Games account. The interaction with Google Play Games is limited to the management of
              game-related activities and achievements. We do not store any Google user information beyond what is necessary for this specific
              purpose.
            </p>
            <p>
              Note: Users have the option to opt out of data collection by Google Analytics. More details on how to exercise this option are provided
              in the 'Your Data Privacy Choices' section.
            </p>
            <p>
              The app does use third-party services that may collect information used to identify you. Link to the privacy policy of third-party
              service providers used by the app
            </p>
            <ul>
              <li>
                <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">
                  <strong>Google Play Services</strong>
                </a>
              </li>
              <li>
                <a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">
                  <strong>Google Analytics for Firebase</strong>
                </a>
              </li>
              <li>
                <a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">
                  <strong>Firebase Crashlytics</strong>
                </a>
              </li>
            </ul>
            <h2>Use of Google User Data</h2>
            <p>
              Our application accesses your Google user data only after receiving explicit consent through Google OAuth. The scope of access is
              confined to the management of your Google Play Games activities, such as publishing achievements. We ensure that the use, storage, and
              sharing of Google user data are in strict accordance with the permissions granted by you and are solely for the intended functionality
              of publishing game activities. We do not use Google user data for any other purposes than those stated in this policy.
            </p>
            <h1>Log Data</h1>
            <p>
              We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through
              third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”)
              address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use
              of the Service, and other statistics.
            </p>
            <h1>Cookies</h1>
            <p>
              Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser
              from the websites that you visit and are stored on your device's internal memory.
            </p>
            <p>
              This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to
              collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is
              being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
            </p>
            <p>
              If you choose to opt out of our use of Google Analytics, we will also prevent Google Analytics cookies from being stored on your device,
              respecting your decision to limit data collection.
            </p>
            <h1>Service Providers</h1>
            <p>We may employ third-party companies and individuals due to the following reasons:</p>
            <ul>
              <li>To facilitate our Service;</li>
              <li>To provide the Service on our behalf;</li>
              <li>To perform Service-related services; or</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </ul>

            <p>
              We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the
              tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
            </p>

            <h1>Your Data Privacy Choices</h1>
            <p>
              We recognize the importance of your privacy choices. In our app, you have the option to opt out of data collection by Google Analytics.
              If you choose to opt out, we will not collect or send any data from your device to Google Analytics for analysis. Instructions for
              opting out can be found within the app settings.
            </p>
            <h1>Security</h1>
            <p>
              We value your trust in providing us your Personal Information and Google user data, thus we are striving to use commercially acceptable
              means of protecting it. We implement adequate security measures to safeguard your data from unauthorized access, alteration, disclosure,
              or destruction. However, remember that no method of transmission over the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </p>
            <h1>Links to Other Sites</h1>
            <p>
              This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these
              external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no
              control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
            </p>
            <h1>Children's Privacy</h1>
            <p>
              These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children
              under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete
              this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information,
              please contact us so that we will be able to do the necessary actions.
            </p>
            <h1>Changes to This Privacy Policy</h1>
            <p>
              We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will
              notify you of any changes by posting the new Privacy Policy on this page.
            </p>

            <p>This policy is effective as of 2024-01-01</p>

            <h1>Contact Us</h1>

            <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at ludussupercompany@gmail.com</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
