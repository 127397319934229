// styles/GlobalStyles.js
import { createGlobalStyle } from "styled-components";
import tw, { globalStyles as baseStyles } from "twin.macro";

const GlobalStyles = createGlobalStyle`
  ${baseStyles}

  @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;900&display=swap');

  body {
    ${tw`antialiased`}
    font-family: 'Nunito', sans-serif !important;
  }

  * {
    font-family: 'Nunito', sans-serif !important;
  }

  /* Below animations are for modal created using React-Modal */
  .ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
  }
  .ReactModal__Overlay--after-open {
    transform: scale(1);
  }
  .ReactModal__Overlay--before-close {
    transform: scale(0);
  }
`;

export default GlobalStyles;
