import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/supersmartlogo.svg";
import { GooglePlayButton, AppStoreButton, ButtonsContainer as BaseButtonsContainer } from "react-mobile-app-button";
import { useTranslation } from "react-i18next";

const Container = tw.div`flex justify-center items-center`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center justify-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl`;
const HeadingSmall = tw.h1`my-5 font-black text-2xl md:text-3xl leading-snug max-w-3xl`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;
const ParagraphSmall = tw.p`my-5 lg:my-8 text-xs lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-col gap-5 mt-4`}
  a {
    ${tw`flex items-center justify-center`}
  }
  @media (min-width: 640px) {
    ${tw`flex-row`}
  }
`;

const ButtonWrapper = styled.div`
  ${tw`w-full sm:w-auto flex-shrink-0`}
  .react-mobile-app-button {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const googlePlayUrl = "https://play.google.com/store/apps/details?id=com.smartcraft";
const appStoreUrl = "https://apps.apple.com/fi/app/supersmart-go/id6469998905";

export default ({ imageSrc = DesignIllustration, imageCss = null, imageDecoratorBlob = false }) => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>{t("play_anytime_anywhere")}</Heading>
            <HeadingSmall>{t("description_headline")}</HeadingSmall>
            <ParagraphSmall>{t("description_small")}</ParagraphSmall>
            <Actions>
              <ButtonsContainer>
                <ButtonWrapper>
                  <a href={appStoreUrl} target="_blank" rel="noopener noreferrer">
                    <AppStoreButton height="50" width="100%" theme={"light"} />
                  </a>
                </ButtonWrapper>
                <ButtonWrapper>
                  <a href={googlePlayUrl} target="_blank" rel="noopener noreferrer">
                    <GooglePlayButton height="50" width="100%" theme={"light"} />
                  </a>
                </ButtonWrapper>
              </ButtonsContainer>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img css={[tw`rounded-4xl w-64 h-64 md:w-96 md:h-96`, imageCss]} src={imageSrc} alt="Hero" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
      </Container>
    </>
  );
};
