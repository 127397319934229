import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const Container = tw.div`flex justify-center items-center h-auto w-auto bg-gray-100 p-0 m-0`;
const VideoWrapper = styled.div`
  ${tw`w-full max-w-6xl`}
  iframe {
    ${tw`w-full h-[40rem]`}
  }
`;

export default ({ videoUrl = "https://youtu.be/OqqQ1E60Xko" }) => {
  return (
    <Container>
      <VideoWrapper>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/OqqQ1E60Xko?si=hgv_u6OmgpafKjyk"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </VideoWrapper>
    </Container>
  );
};
