import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import menu from "../../images/screenshots/menu.PNG";
import points from "../../images/screenshots/points.PNG";
import packs from "../../images/screenshots/packs.PNG";
import game1 from "../../images/screenshots/game1.PNG";
import game2 from "../../images/screenshots/game2.PNG";
import game3 from "../../images/screenshots/game3.PNG";
import game4 from "../../images/screenshots/game4.PNG";
import win from "../../images/screenshots/win.PNG";
import { useTranslation } from "react-i18next";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [tw`mt-24 md:flex justify-center items-center`, props.reversed ? tw`flex-row-reverse` : "flex-row"]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-contain bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8 bg-no-repeat`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const { t } = useTranslation();

  const cards = [
    {
      imageSrc: menu,
      subtitle: t("whos_playing"),
      title: t("add_players"),
      description: t("add_players_text"),
    },

    {
      imageSrc: packs,
      subtitle: t("packs_text"),
      title: t("set_questionpacks"),
      description: t("questionpack_text"),
    },
    {
      imageSrc: points,
      subtitle: t("game_length"),
      title: t("set_points"),
      description: t("set_points_text"),
    },
    {
      imageSrc: game1,
      subtitle: t("think_you_know"),
      title: t("game_begins"),
      description: t("game_begins_text"),
    },
    {
      imageSrc: game3,
      subtitle: t("say_it"),
      title: t("say_it_out_loud"),
      description: t("say_it_text"),
    },
    {
      imageSrc: game4,
      subtitle: t("judgement"),
      title: t("was_it_correct"),
      description: t("judgement_text"),
    },
    {
      imageSrc: game2,
      subtitle: t("round_round"),
      title: t("take_turns"),
      description: t("take_turns_text"),
    },
    {
      imageSrc: win,
      subtitle: t("winning"),
      title: t("bask_in_glory"),
      description: t("winning_text"),
    },
  ];

  return (
    <Container id="howtoplay">
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>{t("how_to_smart")}</HeadingTitle>
          <HeadingDescription>{t("simple_and_easy")}</HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
