import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/VerticalWithAlternateImageAndText";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "pages/TermsOfService";
import FullWidthWithImage from "components/hero/FullWidthWithImage";

import FAQ from "components/faqs/SingleCol.js";
import GetStartedLight from "components/cta/GetStartedLight.js";
import GetStarted from "components/cta/GetStarted.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import GlobalStyles from "styles/GlobalStyles";
import "./i18n";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const MainContent = tw.div`pt-20`;

  return (
    <>
      <GlobalStyles />

      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <AnimationRevealPage disabled>
                <Hero roundedHeaderButton={true} />

                <FullWidthWithImage />
                <GetStartedLight />
                <Features />
                <FAQ />
                <GetStarted />
                <Footer />
              </AnimationRevealPage>
            }
          />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
        </Routes>
      </Router>
    </>
  );
}
